/*
  zip([1, 2, 3]); // [[1], [2], [3]]
  zip([1, 2, 3], ['a', 'b', 'c']); // [[1, 'a'], [2, 'b'], [3, 'c']]
  zip([1, 2], ['a', 'b'], [true, false]); //[[1, 'a', true], [2, 'b', false]]

  zip([1, 2, 3], ['a', 'b'], [true]);
  // [[1, 'a', true], [2, 'b', undefined], [3, undefined, undefined]]

  zip(undefined, {}, false, 1, 'foo'); // throws
  zip([1, 2], ['a', 'b'], undefined, {}, false, 1, 'foo'); // throws
*/

type TupleZip<T> = {
	[I in keyof T]: T[I] extends (infer U)[] ? U | undefined : undefined
}

function zip<T>(...arrays: T[][]): Array<TupleZip<T>> {
	const maxLen = arrays.reduce((max, arr) => Math.max(max, arr.length), 0)
	const result = [] as Array<TupleZip<T>>

	for (let i = 0; i < maxLen; i++) {
		const group = arrays.map((arr) => arr[i]) as TupleZip<T>
		result.push(group)
	}

	return result
}

export default zip
